<template>
	<!-- Filtro por fechas -->
	<div class="ml-auto" :style="$store.state.windowSize.x < 960 ? 'width:100%':''">
		<div class="d-flex align-center ml-auto" v-if="$store.state.windowSize.x > 960">
			<b style="font-weight:500;font-size:12px" class="mt-1 ml-auto">DEL</b>

			<div style="border-radius:6px" class="px-4">
				<v-menu
					style="background:transparent"
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							:dark="marca=='Betgana Races'"
							class="field-date"
							style="max-width:130px;"
							:background-color="colors.panelbar"
							single-line
							dense
							solo
							flat
							hide-details
							v-model="date"
							append-icon="mdi-calendar"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="error" @click="menu = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date);ok()">OK</v-btn>
					</v-date-picker>
				</v-menu>
			</div>

			<b style="font-weight:500;font-size:12px" class="mt-1 mr-4">AL</b>

			<div style="border-radius:6px" class="pr-4">
				<v-menu
					ref="menu1"
					v-model="menu1"
					:close-on-content-click="false"
					:return-value.sync="date1"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							class="field-date"
							:dark="marca=='Betgana Races'"
							style="max-width:130px;"
							:background-color="colors.panelbar"
							single-line
							dense
							solo
							flat
							hide-details
							v-model="date1"
							append-icon="mdi-calendar"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="date1" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="error" @click="menu1 = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.menu1.save(date1);ok();">OK</v-btn>
					</v-date-picker>
				</v-menu>
			</div>
			<v-text-field
				class="field-date"
				style="margin:0px 0 0;padding:0;"
				single-line
				dense
				solo
				flat
				hide-details
				:value="value"
				v-on:input="$emit('input', $event)"
				append-icon="mdi-magnify"
				label="Buscar en la tabla"
			></v-text-field>
		</div>

		<div class="d-flex align-center" style="width:100%" v-else>
			<b style="font-weight:500;font-size:12px" class="mt-1 mr-auto">DEL</b>

			<div style="border-radius:6px" class="px-0 mx-auto">
				<v-menu
					style="background:transparent"
					ref="menu"
					v-model="menu"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							class="field-date"
							style="max-width:130px;"
							background-color="#f5f5f5"
							single-line
							dense
							solo
							flat
							hide-details
							v-model="date"
							append-icon="mdi-calendar"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="error" @click="menu = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date);ok()">OK</v-btn>
					</v-date-picker>
				</v-menu>
			</div>

			<b style="font-weight:500;font-size:12px" class="mt-1 mx-auto">AL</b>

			<div style="border-radius:6px" class="ml-auto">
				<v-menu
					ref="menu1"
					v-model="menu1"
					:close-on-content-click="false"
					:return-value.sync="date1"
					transition="scale-transition"
					offset-y
					min-width="290px"
				>
					<template v-slot:activator="{ on }">
						<v-text-field
							class="field-date"
							style="max-width:130px;"
							background-color="#f5f5f5"
							single-line
							dense
							solo
							flat
							hide-details
							v-model="date1"
							append-icon="mdi-calendar"
							readonly
							v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="date1" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="error" @click="menu1 = false">Cancel</v-btn>
						<v-btn text color="primary" @click="$refs.menu1.save(date1);ok();">OK</v-btn>
					</v-date-picker>
				</v-menu>
			</div>
		</div>
		<v-text-field
			v-if="$store.state.windowSize.x < 960"
			class="field-date mt-2"
			style="margin:0px 0 0;padding:0;"
			background-color="#f5f5f5"
			single-line
			dense
			solo
			flat
			hide-details
			:value="value"
			v-on:input="$emit('input', $event)"
			append-icon="mdi-magnify"
			label="Buscar en la tabla"
		></v-text-field>
	</div>
	<!-- FIn filtro por fechas -->
</template>

<script>
export default {
	name: "filter-table",
	props: {
		dateTo: {
			type: String
		},
		dateFrom: {
			type: String
		},
		value: {
			type: String
		}
	},
	data() {
		return {
			menu: false,
			menu1: false,
			date: this.dateTo,
			date1: this.dateFrom
		};
	},
	computed: {
		marca() {
			return this.$store.state.marca;
		},
		colors() {
			return this.$store.state.colors;
		}
	},
	methods: {
		ok() {
			this.$emit("ok", this.date, this.date1);
		}
	}
};
</script>

<style>
.field-date.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
	min-height: 32px !important;
}
.v-text-field.v-text-field--solo .v-label {
	font-weight: 400 !important;
	font-size: 15px;
}
</style>