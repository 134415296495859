import Vue from "vue";
import Vuex from "vuex";
import axios from "axios"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mode: false,
    dialogRe: false,
    dialogPay: false,
    dialogAccount: false,
    dialogChangePass: false,
    dialogResetPass: false,
    dialogChannel: false,
    dialogEvents: false,
    dialogRules: false,
    dialogProgramas: false,
    msg: "",
    snack: false,
    cash: false,
    nombre: "",
    emailUser: "",
    cedula:"",
    codreferido:"",
    pais_user:"",
    logoW: 80,
    logoName: false,
    footer: false,
    premios: 0,
    idpromodia: 0,
    nbpromodia:"",
    multiplo: 0,
    unidad: 0,
    token: null,
    id_tw: null,
    windowSize: {
      x: 0,
      y: 0,
    },
    selectedEvent: {},
    events: [],
    colors: {},
    carrusel: [],
    contryCurrency: [],
    social: null,
    marca: null,
    logo: "",
    eslogan: null,
    email: null,
    telefono:null,
    dominio: null,
    registro: 0,
    cw: "",

    saldos: [],
    simbolo: 0,
    currentBalance: 0,
    currentBono: 0,
    idmoneda: 0,
    moneda: "",
    loadingSaldo: false,

    idsb: null,
    idpos: null,

    filter: false
  },
  actions: {
    getSaldo({ commit }) {
      commit('setLoadingSaldo', true)
      commit('setSaldo', [])
      axios({
        method: "get",
        url: `/new_saldo`,
      }).then((response) => {
        console.log(response)
        commit('setIdMoneda', response.data.saldo[0].idmoneda)
        commit('setSaldo', response.data.saldo)
        commit('setCurrentBalance', response.data.saldo)
        commit('setLoadingSaldo', false)
      });
    },

    changeLoadingSaldo({ commit }) {
      commit('setLoadingSaldo', true)
    }
  },
  mutations: {
    setIdMoneda(state, data) {
      state.idmoneda = data
    },
    setSaldo(state, data) {
      state.saldos = data
    },
    setCurrentBalance(state, data) {
      state.idmoneda = data[0].idmoneda;
      state.moneda = data[0].moneda;
      state.simbolo = data[0].simbolo
      state.currentBalance = data[0].balance
      state.currentBono = data[0].bono
    },
    setLoadingSaldo(state, data) {
      state.loadingSaldo = data
    },
  },
  modules: {},
});
