var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":_vm.colors.sidebar}},[_c('v-card-text',{staticClass:"py-0 px-0"},[_c('v-card-title',{staticClass:"py-2 d-flex align-center white--text"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.replace('/sport')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('b',{staticClass:"mt-1 text-body-2",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v("Jugadas Deportivas")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-0 mr-3",attrs:{"depressed":"","color":"accent","elevation":"0","outlined":""},on:{"click":_vm.updateTable}},[_c('v-icon',[_vm._v("mdi-update")]),_vm._v(" Actualizar")],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"horse",attrs:{"dense":"","search":_vm.search,"loading":_vm.loading,"sort-by":['fecha'],"sort-desc":[true],"height":_vm.$store.state.windowSize.x < 940?'calc(90vh - 40px)':'calc(100vh - 210px)',"fixed-header":"","headers":_vm.headers,"items":_vm.operations,"items-per-page":100},scopedSlots:_vm._u([{key:"item.ticket",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.ticket))])]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.colors.primary,"dark":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.details == 'apuesta'),expression:"item.details == 'apuesta'"}]},[_vm._v("-")]),_vm._v(" "+_vm._s(item.monto)+" / "+_vm._s(item.detalle.winAmount)+" ")])]}},{key:"item.detalle",fn:function(ref){
var item = ref.item;
return [_c('b',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(item.detalle.description))]),_c('br'),_vm._l((item.detalle.Details),function(ite,i){return _c('b',{key:i + 'b',staticStyle:{"font-weight":"400"}},[_vm._v(_vm._s(ite.completeTeamDescription)),_c('br')])})]}},{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(item.estatus == 'Ganador')?_c('span',{staticClass:"text-capitalize font-weight-bold green--text"},[_vm._v(_vm._s(item.estatus))]):(item.estatus == 'Perdedor')?_c('span',{staticClass:"text-capitalize font-weight-bold red--text"},[_vm._v(_vm._s(item.estatus))]):_c('span',{staticClass:"text-capitalize font-weight-bold orange--text"},[_vm._v(_vm._s(item.estatus))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }