<template>
	<v-card flat color="transparent" :dark="marca == 'Betgana Races'" class="mb-1">
		<div class="d-flex align-center mb-2 px-3 px-md-0 px-lg-0">
			<div
				v-if="size>960"
				style="background:#315a8c;width:100%;height:36px;border-radius:5px"
				:style="`background:${colors.panelbar}`"
				class="d-flex align-center pl-2 mr-2"
			>
				<v-skeleton-loader
					:loading="loading"
					transition="faded"
					type="heading"
					width="100%"
					class="skel"
				>
					<h3 class="d-flex align-center font-weight-medium" style="text-transform:capitalize">
						{{event.nombre}}
						<v-chip
							v-if="event.tipo != 'GALGODROMO'"
							class="ml-2 mr-auto"
							:color="colors.primary"
							small
							dark
							style="padding:10px 9px;border-radius:50%"
						>
							<span :class="marca == 'Betgana Races'?'black--text':''">{{event.nivel}}</span>
						</v-chip>
					</h3>
				</v-skeleton-loader>
			</div>
			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn
						style="border-radius:5px"
						:loading="loading"
						:dark="size<960"
						depressed
						:color="size>960? colors.panelbar: 'transparent'"
						class="ml-md-auto"
						:class="size<960?'px-1':''"
						v-on="on"
					>
						Carrera {{event.idcarrera}}
						<v-chip
							v-if="size>960"
							:color="color(event.estatus)"
							x-small
							:dark="event.estatus != 'CORRIENDO'"
							class="ml-3"
							style="padding:10px 10px;cursor:pointer"
						>
							<small>
								<b :class="marca == 'Betgana Races'?'black--text':''">{{event.estatus}}</b>
							</small>
						</v-chip>
						<v-icon class="ml-2">mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in races" :key="index" @click="changeRace(item,true)">
						<v-list-item-title class="d-flex align-center">
							<small class="mr-2">
								<b>CARRERA {{ item.idcarrera }}</b>
							</small>
							<v-chip
								@click="()=>{}"
								class="mx-2"
								x-small
								:dark="item.estatus != 'CORRIENDO'"
								:color="color(item.estatus)"
								style="padding:10px 10px"
							>
								<small>
									<b
										:class="marca == 'Betgana Races' && item.estatus != 'CERRADA'?'black--text':''"
									>{{item.estatus}}</b>
								</small>
							</v-chip>
							<v-icon>mdi-menu-right</v-icon>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<!-- <div>
				<v-chip small dark label color="#00afef" v-if="event.estatus == 'ABIERTA'">
					<b>{{event.estatus}}</b>
				</v-chip>
				<v-chip small label color="#ffc107" v-if="event.estatus == 'CORRIENDO'">
					<b>{{event.estatus}}</b>
				</v-chip>
				<v-chip small dark label color="#eb262c" v-if="event.estatus == 'CERRADA'">
					<b>{{event.estatus}}</b>
				</v-chip>
			</div>-->

			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn
						style="border-radius:5px"
						:loading="loading"
						depressed
						class="mx-auto mx-md-1"
						:color="size>960? colors.panelbar:'transparent'"
						:dark="size<960"
						:class="size<960?'px-1':''"
						v-on="on"
					>
						{{selectedGameType}}
						<v-icon class="pr-0 mr-0">mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in gameType" :key="index" @click="changeGameType(item)">
						<v-list-item-title class="d-flex">
							<small class="mr-2">
								<b>{{ item }}</b>
							</small>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn
						style="border-radius:5px"
						:loading="loading"
						depressed
						class="mr-lg-0"
						:color="size>960? colors.panelbar:'transparent'"
						:dark="size<960"
						:class="size<960?'px-1':''"
						v-on="on"
					>
						UND {{selectedAmout}}
						<v-icon class>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in amount" :key="index" @click="changeAmount(item)">
						<v-list-item-title class="d-flex">
							<small class="mr-2">
								<b>UND {{ item }}</b>
							</small>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-tooltip color="red darken-1" dark v-model="show" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="$store.state.currentBalance.toLocaleString() < '1.00'"
						@mouseleave="show = false"
						@mouseenter="show = true"
						v-bind="attrs"
						v-show="size>1264 && canal != ''"
						:loading="loading"
						:color="colors.primary"
						dark
						depressed
						class="ml-1 px-2"
						@click="show = true"
					>
						<span :class="marca == 'Betgana Races'?'black--text':''">
							<v-icon>mdi-play-circle-outline</v-icon>
							{{canal != ""?'En vivo':'CANALES EN VIVO'}}
						</span>
					</v-btn>
					<v-btn
						v-else
						v-show="size>1264 && canal != ''"
						:loading="loading"
						:color="colors.primary"
						dark
						depressed
						class="ml-1 px-2"
						@click="canales"
					>
						<span :class="marca == 'Betgana Races'?'black--text':''">
							<v-icon>mdi-play-circle-outline</v-icon>
							{{canal != ""?'En vivo':'CANALES EN VIVO'}}
						</span>
					</v-btn>
				</template>
				<span>Por favor recargue su saldo para ver los canales en vivo</span>
			</v-tooltip>

			<v-btn
				style="border-radius:5px"
				v-show="size>1264"
				:loading="loading"
				:color="colors.error"
				dark
				depressed
				class="ml-1 px-2"
				:class="size<1264? 'ml-1':''"
				@click="cleanJugada()"
			>Limpiar</v-btn>
		</div>
		<div class="d-flex">
			<v-tooltip color="red darken-1" dark v-model="show1" top>
				<template v-slot:activator="{ on, attrs }">
					<div style="width:49.8%" v-if="$store.state.currentBalance.toLocaleString() < '1.00'">
						<v-btn
							@mouseleave="show1 = false"
							@mouseenter="show1 = true"
							v-bind="attrs"
							v-if="(size<1264 && size>960) && canal != ''"
							:loading="loading"
							:color="colors.primary"
							dark
							depressed
							class="mb-2"
							style="width:100%"
							@click="show1 = true"
						>
							<span :class="marca == 'Betgana Races'?'black--text':''">
								<v-icon>mdi-play-circle-outline</v-icon>EN VIVO
							</span>
						</v-btn>
					</div>
					<div v-else style="width:49.8%">
						<v-btn
							v-if="(size<1264 && size>960) && canal != ''"
							:loading="loading"
							:color="colors.primary"
							dark
							depressed
							class="mb-2"
							@click="canales"
							style="width:100%"
						>
							<span :class="marca == 'Betgana Races'?'black--text':''">
								<v-icon>mdi-play-circle-outline</v-icon>EN VIVO
							</span>
						</v-btn>
					</div>
				</template>
				<span>Por favor recargue su saldo para ver los canales en vivo</span>
			</v-tooltip>

			<v-btn
				style="width:49.8%;border-radius:5px"
				v-if="(size<1264 && size>960)"
				:loading="loading"
				:color="colors.error"
				dark
				depressed
				class="ml-1 px-2"
				:class="size<1264? 'ml-1':''"
				@click="cleanJugada()"
			>Limpiar</v-btn>
		</div>

		<!-- <template v-if="size<400">
			<v-menu offset-y>
				<template v-slot:activator="{ on }">
					<v-btn
						:loading="loading"
						:disabled="event.estatus == 'CERRADA'"
						depressed
						:color="colors.panelbar"
						class="mr-0 mr-lg-0 mb-2"
						dark
						v-on="on"
						block
					>
						UND {{selectedAmout}}
						<v-icon class>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in amount" :key="index" @click="changeAmount(item)">
						<v-list-item-title class="d-flex">
							<small class="mr-2">
								<b>UND {{ item }}</b>
							</small>
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</template>-->
		<div class="d-flex align-center event-details mb-2" v-if="size>960">
			<v-btn class="ml-2" depressed text rounded small dark>{{total}} UND</v-btn>
			<span
				class="body-2 font-weight-medium px-2 white--text ml-2 mx-auto rounded-pill"
				style="background:#2b4564; width:100%;padding:4px 0"
				:style="`background:${colors.even}`"
			>
				<span
					class="ml-1"
					:class="!mode?'black--text':''"
					v-if="jugada.length == 0 && jugada2.length == 0 && jugada3.length == 0 && jugada4.length == 0"
				>SIN SELECCIÓN</span>
				<span class="black--text" v-for="(i,index) in jugada" :key="index+'a'">
					<span v-if="index !== 0">-</span>
					<span>{{i}}</span>
				</span>
				<span v-if="jugada2.length && jugada.length">/</span>
				<span class="black--text" v-for="(i,index) in jugada2" :key="index+'b'">
					<span v-if="index !== 0">-</span>
					<span>{{i}}</span>
				</span>
				<span v-if="jugada3.length && jugada2.length">/</span>
				<span class="black--text" v-for="(i,index) in jugada3" :key="index+'c'">
					<span v-if="index !== 0">-</span>
					<span>{{i}}</span>
				</span>
				<span v-if="jugada4.length && jugada3.length">/</span>
				<span class="black--text" v-for="(i,index) in jugada4" :key="index+'d'">
					<span v-if="index !== 0">-</span>
					<span>{{i}}</span>
				</span>
			</span>
			<v-btn
				small
				:color="marca == 'Betgana Races'? colors.warning:'white'"
				:disabled="total==0"
				class="mx-2"
				rounded
				depressed
				dark
				@click="jugar"
			>
				<span
					:class="marca == 'Betgana Races'?total==0?'':'black--text':total==0?'':'black--text'"
				>jugar</span>
			</v-btn>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		event: Object,
		races: Array,
		gameType: Array,
		selectedGameType: String,
		selectedAmout: [String, Number],
		amount: Array,
		loading: Boolean,
		jugada: Array,
		jugada2: Array,
		jugada3: Array,
		jugada4: Array,
		total: [String, Number],
		canal: String,
	},
	data() {
		return {
			show: false,
			show1: false,
		};
	},
	computed: {
		marca() {
			return this.$store.state.marca;
		},
		mode() {
			return this.$store.state.mode;
		},
		size() {
			return this.$store.state.windowSize.x;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		canales() {
			this.$emit("canales");
		},
		jugar() {
			this.$emit("jugar");
		},
		cleanJugada() {
			this.$emit("cleanJugada");
		},
		changeAmount(item) {
			this.$emit("changeAmount", item);
		},
		changeGameType(item) {
			this.$emit("changeGameType", item);
		},
		changeRace(item, t) {
			this.$emit("changeRace", item, t);
		},
		color(v) {
			if (v == "ABIERTA") {
				return this.colors.primary;
			} else if (v == "CORRIENDO") {
				return this.colors.warning;
			} else {
				return this.colors.error;
			}
		},
	},
};
</script>

<style>
.event-details {
	background: linear-gradient(
		0deg,
		var(--select-0) 0%,
		var(--select-1) 100%
	) !important;
	height: 36px;
	background: #fff;
	border-radius: 5px !important;
	color: #fff;
}
.event-details .event-title {
	letter-spacing: 0.0892857143em;
	font-size: 16px;
}
.event-details-m {
	background: rgba(0, 0, 0, 0.2);
	border-top-left-radius: 16px !important;
	border-bottom-right-radius: 16px !important;
}
.skel .v-skeleton-loader__heading {
	width: 90%;
}
</style>