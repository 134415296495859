<template>
	<v-card :color="colors.sidebar">
		<v-card-text class="py-0 px-0">
			<v-card-title class="py-2 d-flex align-center white--text">
				<v-btn icon @click="$router.replace('/sport')">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn><b
					class="mt-1 text-body-2"
					style="font-weight:500;font-size:14px"
				>Jugadas Deportivas</b>
				<v-spacer></v-spacer>

				<v-btn
          class="mt-0 mr-3"
          depressed
          color="accent"
          elevation="0"
          outlined
          @click="updateTable"
          ><v-icon>mdi-update</v-icon> Actualizar</v-btn
        >
				
				
			</v-card-title>
			<v-divider></v-divider>
			<v-data-table
				dense
				:search="search"
				class="horse"
				:loading="loading"
				:sort-by="['fecha']"
				:sort-desc="[true]"
				:height="$store.state.windowSize.x < 940?'calc(90vh - 40px)':'calc(100vh - 210px)'"
				fixed-header
				:headers="headers"
				:items="operations"
				:items-per-page="100"
			>
				<template v-slot:item.ticket="{ item }">
					<span class="font-weight-bold">{{item.ticket}}</span>
				</template>

				<template v-slot:item.monto="{ item }">
					<v-chip small :color="colors.primary" dark>
						<div v-show="item.details == 'apuesta'">-</div>
						{{ item.monto }} / {{ item.detalle.winAmount }}
					</v-chip>
				</template>
				<template v-slot:item.detalle="{ item }">
					<b style="font-weight:700">{{ item.detalle.description }}</b><br>
					<b style="font-weight:400" 
					v-for="(ite, i) in item.detalle.Details"


					:key="i + 'b'">{{ite.completeTeamDescription }}<br></b>
				</template>

				<template v-slot:item.estatus="{ item }">
					<span v-if="item.estatus == 'Ganador'" class="text-capitalize font-weight-bold green--text">{{item.estatus}}</span>
					<span v-else-if="item.estatus == 'Perdedor'" class="text-capitalize font-weight-bold red--text">{{item.estatus}}</span>
					<span v-else class="text-capitalize font-weight-bold orange--text">{{item.estatus}}</span>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";
//import filterTable from "../../components/filterTable.vue";

export default {
	components: {
		//filterTable,
	},
	data() {
		return {
			loading: false,
			search: "",
			operations: [],
			date: "",
			date1: date.today(),
			headers: [
				{ text: "Date", value: "fecha", sortable: true },
				{ text: "Ticket", value: "ticket", sortable: false },
				{ text: "Jugada", value: "detalle", sortable: false },
				{ text: "Monto/Paga", value: "monto", align: "center", sortable: false },
				{ text: "Estatus", value: "estatus", sortable: true },
			],
			bono: 0,
		};
	},
	watch: {
		bono() {
			this.getOperations(this.idmoneda);
		},
		idmoneda(e) {
			this.getOperations(e);
		},
	},
	created() {
		this.parseDate();
	},
	mounted() {
		this.getOperations(this.idmoneda);
	},
	computed: {
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		parseDate,
		statusColor,
		updateTable(date, date1) {
			this.date = date;
			this.date1 = date1;
			this.getOperations(this.idmoneda);
		},
		getOperations(id) {
			this.loading = true;
			axios({
				method: "post",
				url: `sportjugadas`,
				params: {
					idmoneda: id,
					bono: this.bono,
				},
			}).then((response) => {
				console.log(response);
				this.operations = response.data.jugadas;
				this.loading = false;
			});
		},
	},
};
</script>

<style>
</style>