<template>
  <v-card :color="colors.sidebar">
    <v-card-text class="py-0 px-0">
      <v-card-title class="color py-2 d-flex align-center white--text">
        <b class="mt-1" style="font-weight: 500; font-size: 14px" v-if="$store.state.windowSize.x > 960">JUGADAS</b>
        <v-radio-group hide-details class="ml-3 mt-0" dense dark v-model="bono" row>
          <v-radio label="Saldo" :value="0"></v-radio>
          <v-radio label="Bono" :value="1"></v-radio>
        </v-radio-group>
        <filter-table v-model="search" :dateTo="date" :dateFrom="date1" @ok="updateTable"></filter-table>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table :search="search" class="horse" :height="$store.state.windowSize.x < 940 ? 'calc(100vh - 296px)' : 'calc(100vh - 246px)'" fixed-header :headers="headers" :items="plays" :expanded.sync="expanded" :loading="loading" show-expand :items-per-page="10" item-key="idticket">
        <template v-slot:item.data-table-expand="{ isExpanded, expand }">
          <v-icon size="28" v-if="!isExpanded" color="green" @click="expand">mdi-plus-circle</v-icon>
          <v-icon size="28" v-else color="#eb262c" @click="expand(false)">mdi-minus-circle</v-icon>
        </template>

        <template v-slot:item.estatus="{ item }">
          <v-chip small :color="statusColor(item.estatus_ticket)" dark>
            <b style="text-transform: uppercase">TICKET {{ item.estatus }}</b>
          </v-chip>
        </template>

        <template v-slot:item.hora="{ item }">{{ item.hora.split(":")[0] }}:{{ item.hora.split(":")[1] }}</template>
        <template v-slot:item.idcarrera="{ item }">#{{ item.idcarrera }}</template>
        <template v-slot:item.actions="{ item }">
          <v-btn depressed :text="$store.state.windowSize.x > 940" :small="$store.state.windowSize.x > 940" :block="$store.state.windowSize.x < 940" v-if="item.estatus_carrera == 'CERRADA' || item.estatus_ticket == 'CANCELADO'" :disabled="item.estatus_carrera == 'CERRADA' || item.estatus_ticket == 'CANCELADO'">
            <v-icon color="grey">mdi-cancel</v-icon>
          </v-btn>
          <v-btn v-else @click="confirm(item.idticket)" :text="$store.state.windowSize.x > 940" :disabled="loading" :loading="loading" color="#eb262c" :small="$store.state.windowSize.x > 940" :block="$store.state.windowSize.x < 940" dark depressed>
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.monto_jugada="{ item }">
          <b>{{ item.monto_jugada }} UND</b>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="6">
                <b>Detalles</b>
                <v-divider></v-divider>
                <v-row>
                  <v-col class="pt-1 pb-0 pb-md-1">
                    <div class="d-flex" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Nombre:</b>
                      {{ item.nombre }}
                    </div>
                    <div class="d-flex" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Carrera:</b>
                      #{{ item.idcarrera }}
                    </div>
                    <div class="d-flex" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Tipo:</b>
                      {{ item.idjugadas }}
                    </div>
                  </v-col>
                  <v-col class="py-0 py-md-1">
                    <div class="d-flex" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Cantidad:</b>
                      {{ item.cantidad }} UND
                    </div>
                    <div class="d-flex" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Ticket:</b>
                      {{ item.estatus }}
                    </div>
                    <div class="d-flex align-center" style="width: 100%">
                      <b style="font-weight: 500; width: 72px">Monto:</b>
                      <v-chip x-small color="#eb262c">
                        <b style="font-size: 12px" class="white--text">-{{ item.monto_jugada }} UND</b>
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <b>Selección</b>
                <v-divider></v-divider>
                <div v-if="item.idjugadas == 'GPS' || item.idjugadas == 'GP' || item.idjugadas == 'Ganador' || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta' || item.idjugadas == 'Superfecta'">
                  <b>1°</b>
                  | {{ item.texto_jugada.split("/")[0] }}
                </div>
                <div v-else-if="item.idjugadas == 'Pick 2' || item.idjugadas == 'Pick 3' || item.idjugadas == 'Pick 4'">
                  <b>1° |</b>
                  {{ item.texto_jugada.split("/")[0] }}
                  <b style="color: #eb262c">|</b>
                  {{ item.texto_jugada.split("/")[1] }}
                  <b style="color: #eb262c">|</b>
                  {{ item.texto_jugada.split("/")[2] }}
                  <b style="color: #eb262c">|</b>
                  {{ item.texto_jugada.split("/")[3] }}
                </div>
                <v-divider></v-divider>
                <div v-if="item.idjugadas == 'GPS' || item.idjugadas == 'GP' || item.idjugadas == 'Place' || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta' || item.idjugadas == 'Superfecta'">
                  <b>2°</b>
                  | {{ item.idjugadas == "Place" ? item.texto_jugada.split("/")[0] : item.idjugadas == "GPS" ? item.texto_jugada.split("/")[0] : item.idjugadas == "GP" ? item.texto_jugada.split("/")[0] : item.texto_jugada.split("/")[1] }}
                </div>
                <div v-if="item.idjugadas == 'GPS' || item.idjugadas == 'Show' || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta'">
                  <v-divider></v-divider>
                  <b>3°</b>
                  | {{ item.idjugadas == "Show" ? item.texto_jugada.split("/")[0] : item.idjugadas == "GPS" ? item.texto_jugada.split("/")[0] : item.texto_jugada.split("/")[2] }}
                </div>
                <div v-if="item.idjugadas == 'Superfecta'">
                  <v-divider></v-divider>
                  <b>4°</b>
                  | {{ item.texto_jugada.split("/")[3] }}
                </div>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
      <div class="d-flex align-center">
        <h4 class="pa-3 white--text text-uppercase font-weight-regular">
          Monto total jugado
          <v-chip small color="#eb262c" class="ml-1">
            <b style="font-size: 12px" class="white--text">{{ total }} UND</b>
          </v-chip>
        </h4>
      </div>
    </v-card-text>
    <confirm title="ANULAR TICKET" text="El ticket se anulara esta acción no se puede reveritr." :open="dialog" @ok="cancelTicket" @close="close" :loading="loading"></confirm>
  </v-card>
</template>

<script>
import axios from "axios";
import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";
import FilterTable from "../../components/filterTable.vue";
import Confirm from "../../components/Confirm.vue";

export default {
  components: {
    FilterTable,
    Confirm,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      expanded: [],
      search: "",
      plays: [],
      date: "",
      date1: date.today(),
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "FECHA", value: "fecha" },
        { text: "HORA", value: "hora" },
        { text: "REFERENCIA", value: "idticket" },
        { text: "HIPODROMO", value: "nombre" },
        { text: "TIPO", value: "idjugadas" },
        { text: "ESTATUS", value: "estatus", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      id: null,
      bono: 0,
    };
  },
  watch: {
    bono() {
      this.getPlays(this.idmoneda);
    },
    idmoneda(e) {
      this.getPlays(e);
    },
  },
  created() {
    this.parseDate();
  },
  mounted() {
    this.getPlays(this.idmoneda);
  },
  computed: {
    idmoneda() {
      return this.$store.state.idmoneda;
    },
    colors() {
      return this.$store.state.colors;
    },
    total() {
      let monto = 0.0;
      this.plays.map((e) => {
        monto = monto + parseFloat(e.monto_jugada);
      });
      return monto;
    },
  },
  methods: {
    parseDate,
    statusColor,
    updateTable(date, date1) {
      this.date = date;
      this.date1 = date1;
      this.getPlays(this.idmoneda);
    },
    getPlays(id) {
      this.loading = true;
      axios({
        method: "get",
        url: `new_jugadas/${this.date}/${this.date1}`,
        params: {
          idmoneda: id,
          bono: this.bono,
        },
      }).then((response) => {
        this.plays = response.data.jugadas;
        this.loading = false;
      });
    },
    confirm(value) {
      this.id = value;
      this.dialog = true;
    },
    cancelTicket() {
      this.loading = true;
      axios({
        method: "post",
        url: `new_anularticket`,
        data: {
          idticket: this.id,
        },
      }).then((response) => {
        if (response.data.mensaje != "La carrera asociada a la apuesta se encuentra cerrada") {
          this.updateTable(this.date, this.date1);
        }
        this.$store.state.snack = true;
        this.$store.state.msg = response.data.mensaje;
        this.dialog = false;
        this.loading = false;
        this.id = null;
      });
    },
    close(value) {
      this.dialog = value;
    },
  },
};
</script>

<style></style>
