<template>
  <div align = "center">
    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <iframe
      :src="gameUrl"
      :style="
        size < 960
          ? 'width: 97%; height: calc(88vh)'
          : 'width: 100%; height: calc(88vh)'
      "
      frameborder="0" onload="resizeIframe(this)"
    ></iframe>
    <v-btn
      v-if="size < 960"
      small
      fab
      fixed
      top
      left
      color="white"
      @click="$router.replace('/casino')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      gameUrl: null,
      overlay: true,
      isMobile: 0,
    };
  },
  watch: {
    idmoneda() {
      this.overlay = true;
      this.getGame();
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.isMobile = window.innerWidth < 600 ? 1 : 0;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.nombre = response.data.success.name;
    });
    this.getSaldo();

    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
    this.idpos = sessionStorage.getItem("idpos");
    this.getGame();
  },
  updated() {
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
  },
  methods: {
    ...mapActions(["getSaldo"]),
    onResize() {
      this.isMobile = window.innerWidth < 600 ? 1 : 0;
    },
    resizeIframe(obj) {
        alert(obj.contentWindow.location.pathname);
    },
    getGame() {
      /*const headers = {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Cache-Control': 'no-cache'
              
            }*/
   
    axios({
				method: "post",
       // headers: headers,
				url: `https://apitr.xyz/onlineapi/api/opengame`,
				data: {
          token:this.$store.state.token,
          idpos:this.$store.state.idpos,
          symbol: this.$route.params.code
				},
			})
        .then((e) => {
          this.gameUrl = e.data.gameURL;
          console.log(e.data.gameURL);
          this.overlay = false;
        });
    },
  },
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    },
    moneda() {
      return this.$store.state.moneda;
    },
    idmoneda() {
      return this.$store.state.idmoneda;
    },
    idsb() {
      return this.$store.state.idsb;
    },
    currentBalance() {
      return this.$store.state.currentBalance;
    },
    idpos: {
      get() {
        return this.$store.state.idpos;
      },
      set(e) {
        this.$store.state.idpos = e;
      },
    },
  },
};
</script>

<style></style>
