<template>
	<v-card :color="colors.sidebar">
		<v-card-text class="px-0 py-0">
			<v-card-title class="white--text py-2 d-flex align-center">
				<b
					class="mt-1"
					style="font-weight:500;font-size:14px"
					v-if="$store.state.windowSize.x > 960"
				>HISTORIAL DE RETIRO</b>
			</v-card-title>
			<v-divider></v-divider>
			<v-data-table
				class="horse"
				:loading="loading"
				:height="$store.state.windowSize.x < 940?'calc(100vh - 254px)':'calc(100vh - 210px)'"
				fixed-header
				:headers="headers"
				:items="withdrawals"
				:items-per-page="10"
			>
				<template v-slot:item.monto="{ item }">
					<v-chip small :color="statusColor('apuesta')" dark>
						<b>-{{ item.monto }} UND</b>
					</v-chip>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import statusColor from "../../statusColor.js";

export default {
	components: {},
	data() {
		return {
			loading: false,
			search: "",
			withdrawals: [],
			headers: [
				{ text: "FECHA", value: "fecha" },
				{ text: "HORA", value: "hora" },
				{ text: "TIPO", value: "tipo" },
				{ text: "NOMBRE", value: "titular" },
				{ text: "EMAIL", value: "email" },
				{ text: "MONTO", value: "monto" },
				{ text: "ESTATUS", value: "estado", align: "center" },
			],
		};
	},
	watch: {
		idmoneda(e) {
			this.getWithdrawals(e);
		},
	},
	mounted() {
		this.getWithdrawals(this.idmoneda);
	},
	computed: {
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		statusColor,
		getWithdrawals(id) {
			this.loading = true;
			axios({
				method: "get",
				url: `/new_misretiros`,
				params: {
					idmoneda: id,
				},
			}).then((response) => {
				this.withdrawals = response.data.retiros;
				this.loading = false;
			});
		},
	},
};
</script>

<style>
</style>