<template>
	<v-card :color="colors.sidebar">
		<v-card-text class="py-0 px-0">
			<v-card-title class="py-2 d-flex white--text">
				<b
					class="mt-1"
					style="font-weight:500;font-size:14px"
					v-if="$store.state.windowSize.x > 960"
				>TICKETS PREMIADOS</b>
				<filter-table v-model="search" :dateTo="date" :dateFrom="date1" @ok="updateTable"></filter-table>
			</v-card-title>
			<v-divider></v-divider>
			<v-data-table
				class="horse"
				:loading="loading"
				:height="$store.state.windowSize.x < 940?'calc(100vh - 296px)':'calc(100vh - 210px)'"
				fixed-header
				:headers="headers"
				:items="rewards"
				show-expand
				:expanded.sync="expandedPre"
				:items-per-page="10"
				item-key="idticket"
				:search="search"
			>
				<template v-slot:item.data-table-expand="{  isExpanded,expand }">
					<v-icon size="28" v-if="!isExpanded" color="green" @click="expand">mdi-plus-circle</v-icon>
					<v-icon size="28" v-else color="#eb262c" @click="expand(false)">mdi-minus-circle</v-icon>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn
						v-if="item.estatus_pago == ''"
						:disabled="item.estatus_pago != '' || loading"
						class="px-2 animate"
						@click="pagarTicket(item.idticket)"
						:small="$store.state.windowSize.x > 940"
						depressed
						rounded
						:loading="loading"
						:dark="item.estatus_pago == ''"
						block
						:color="colors.primary"
					>Confirmar</v-btn>
					<v-btn small v-else text disabled color="green">
						<b style=";font-size:12px">CONFIRMADO</b>
					</v-btn>
				</template>

				<template v-slot:item.moneda="{ item }">
					<b>{{ item.simbolo }} ({{ item.moneda }})</b>
					<v-chip
						v-if="item.idticket.charAt(0) == 'B'"
						class="ml-2"
						color="red"
						text-color="white"
						small
					>{{item.idticket.charAt(0) == 'B'?'Bono':''}}</v-chip>
				</template>
				<template v-slot:item.pago="{ item }">
					<v-chip small color="#00afef" dark>{{ item.pago }} UND</v-chip>
				</template>
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<v-row>
							<v-col cols="6">
								<div class="d-block d-md-flex">
									<b>Detalles</b>
									<br v-if="$store.state.windowSize.x < 940" />
									<b
										class="ml-auto"
									>Fecha de pago: {{ item.fecha_pago == '0000-00-00'? 'Por confirmar': item.fecha_pago}}</b>
									<br v-if="$store.state.windowSize.x < 940" />
									<b
										class="mx-auto"
									>Hora de pago: {{ item.hora_pago == '00:00:00'? 'Por confirmar': item.hora_pago}}</b>
								</div>
								<v-divider></v-divider>
								<v-row>
									<v-col class="pt-1 pb-0 pb-md-1">
										<div class="d-flex" style="width:100%">
											<b style="font-weight:500;width:72px">Nombre:</b>
											{{ item.nombre}}
										</div>
										<div class="d-flex" style="width:100%">
											<b style="font-weight:500;width:72px">Carrera:</b>
											#{{ item.idcarrera}}
										</div>
										<div class="d-flex" style="width:100%">
											<b style="font-weight:500;width:72px">Tipo:</b>
											{{ item.idjugadas}}
										</div>
									</v-col>
									<v-col class="py-0 py-md-1">
										<div class="d-flex" style="width:100%">
											<b style="font-weight:500;width:72px">Cantidad:</b>
											{{ item.cantidad }} UND
										</div>
										<div class="d-flex align-center" style="width:100%">
											<b style="font-weight:500;width:72px">Monto:</b>
											<v-chip dark x-small color="#eb262c">
												<b style="font-size:12px">-{{ item.monto_jugada}} UND</b>
											</v-chip>
										</div>
										<div class="d-flex align-center" style="width:100%">
											<b style="font-weight:500;width:72px">Pago:</b>
											<v-chip x-small dark color="#00afef">
												<b style="font-size:12px">{{ item.pago}} UND</b>
											</v-chip>
										</div>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="6">
								<b>Selección</b>
								<v-divider></v-divider>
								<div
									v-if="item.idjugadas == 'GPS' || item.idjugadas == 'GP'  || item.idjugadas == 'Ganador'  || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta' || item.idjugadas == 'Superfecta'"
								>
									<b>1°</b>
									| {{ item.texto_jugada.split('/')[0] }}
								</div>
								<div
									v-else-if="item.idjugadas == 'Pick 2' || item.idjugadas == 'Pick 3' || item.idjugadas == 'Pick 4'"
								>
									<b>1°</b>
									| {{ item.texto_jugada.split('/')[0] }}
									<b style="color:#eb262c">|</b>
									{{ item.texto_jugada.split('/')[1] }}
									<b style="color:#eb262c">|</b>
									{{ item.texto_jugada.split('/')[2] }}
									<b style="color:#eb262c">|</b>
									{{ item.texto_jugada.split('/')[3] }}
								</div>
								<v-divider></v-divider>
								<div
									v-if="item.idjugadas == 'GP'  || item.idjugadas == 'GPS' || item.idjugadas == 'Place' || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta' || item.idjugadas == 'Superfecta'"
								>
									<b>2°</b>
									| {{ item.idjugadas == 'Place' ? item.texto_jugada.split('/')[0] : item.idjugadas == 'GPS' ? item.texto_jugada.split('/')[0] : item.idjugadas == 'GP' ? item.texto_jugada.split('/')[0] : item.texto_jugada.split('/')[1] }}
								</div>
								<v-divider></v-divider>
								<div
									v-if="item.idjugadas == 'GPS' || item.idjugadas == 'Show' || item.idjugadas == 'Exacta' || item.idjugadas == 'Trifecta' "
								>
									<b>3°</b>
									| {{ item.idjugadas == 'Show' ? item.texto_jugada.split('/')[0] : item.idjugadas == 'GPS' ? item.texto_jugada.split('/')[0] : item.texto_jugada.split('/')[2] }}
								</div>
								<v-divider></v-divider>
								<div v-if="item.idjugadas == 'Superfecta' ">
									<b>4°</b>
									| {{ item.texto_jugada.split('/')[3] }}
								</div>
							</v-col>
						</v-row>
					</td>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";
import filterTable from "../../components/filterTable.vue";

export default {
	components: {
		filterTable,
	},
	data() {
		return {
			loading: false,
			expandedPre: [],
			search: "",
			rewards: [],
			date: "",
			date1: date.today(),
			headers: [
				{ text: "", value: "data-table-expand" },
				{ text: "FECHA", value: "fecha" },
				{ text: "CARTERA", value: "moneda" },
				{ text: "REFERENCIA", value: "idticket" },
				{ text: "NOMBRE", value: "nombre" },
				// { text: "CARRERA", value: "idcarrera", align: "center" },
				{ text: "TIPO", value: "idjugadas" },
				{ text: "PAGO", value: "pago", align: "center" },
				{ text: "", value: "actions", align: "center" },
			],
		};
	},
	created() {
		this.parseDate();
	},
	mounted() {
		this.getRewards();
	},
	computed: {
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		...mapActions(["getSaldo"]),
		parseDate,
		statusColor,
		updateTable(date, date1) {
			this.date = date;
			this.date1 = date1;
			this.getRewards();
		},
		getRewards() {
			this.loading = true;
			axios({
				method: "get",
				url: `premiados/${this.date}/${this.date1}`,
			}).then((response) => {
				this.rewards = response.data.premiados;
				this.loading = false;
			});
		},
		pagarTicket(value) {
			this.loading = true;
			axios({
				method: "post",
				url: `new_pagarticket`,
				data: {
					idticket: value,
				},
			}).then((response) => {
				// console.log(response);
				this.getRewards();
				this.getPremios();
				this.$store.state.msg = response.data.mensaje;
				this.loading = false;
				this.$store.state.snack = true;
				this.getSaldo();
			});
		},
		getPremios() {
			axios({
				method: "get",
				url: `/new_premios`,
			}).then((response) => {
				this.$store.state.premios = response.data.premios[0];
			});
		},
	},
};
</script>

<style>
</style>