<template>
	<v-card :color="colors.sidebar">
		<v-card-text class="py-0 px-0">
			<v-card-title class="py-2 d-flex align-center white--text">
				<b
					class="mt-1"
					style="font-weight:500;font-size:14px"
					v-if="$store.state.windowSize.x > 960"
				>OPERACIONES</b>
				<v-radio-group hide-details class="mt-0 ml-3" dense dark v-model="bono" row>
					<v-radio label="Saldo" :value="0"></v-radio>
					<v-radio label="Bono" :value="1"></v-radio>
				</v-radio-group>
				<filter-table v-model="search" :dateTo="date" :dateFrom="date1" @ok="updateTable"></filter-table>
			</v-card-title>
			<v-divider></v-divider>
			<v-data-table
				:search="search"
				class="horse"
				:loading="loading"
				:height="$store.state.windowSize.x < 940?'calc(100vh - 296px)':'calc(100vh - 210px)'"
				fixed-header
				:headers="headers"
				:items="operations"
				:items-per-page="10"
			>
				<template v-slot:item.tipo="{ item }">
					<span class="text-capitalize">{{item.tipo}}</span>
				</template>

				<template v-slot:item.monto="{ item }">
					<v-chip small :color="statusColor(item.tipo)" dark>
						<div v-show="item.tipo == 'apuesta'">-</div>
						{{ item.monto }} UND
					</v-chip>
				</template>
				<template v-slot:item.balance="{ item }">
					<b style="font-weight:500">{{ item.balance }} UND</b>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import axios from "axios";
import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";
import filterTable from "../../components/filterTable.vue";

export default {
	components: {
		filterTable,
	},
	data() {
		return {
			loading: false,
			search: "",
			operations: [],
			date: "",
			date1: date.today(),
			headers: [
				{ text: "FECHA", value: "fecha" },
				{ text: "HORA", value: "hora" },
				{ text: "REFERENCIA", value: "ref" },
				{ text: "TIPO", value: "tipo" },
				{ text: "MONTO", value: "monto", align: "center" },
				{ text: "BALANCE", value: "balance" },
			],
			bono: 0,
		};
	},
	watch: {
		bono() {
			this.getOperations(this.idmoneda);
		},
		idmoneda(e) {
			this.getOperations(e);
		},
	},
	created() {
		this.parseDate();
	},
	mounted() {
		this.getOperations(this.idmoneda);
	},
	computed: {
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		parseDate,
		statusColor,
		updateTable(date, date1) {
			this.date = date;
			this.date1 = date1;
			this.getOperations(this.idmoneda);
		},
		getOperations(id) {
			this.loading = true;
			axios({
				method: "get",
				url: `new_transacciones/${this.date}/${this.date1}`,
				params: {
					idmoneda: id,
					bono: this.bono,
				},
			}).then((response) => {
				console.log(response);
				this.operations = response.data.transacciones;
				this.loading = false;
			});
		},
	},
};
</script>

<style>
</style>