<template>
	<div>
	<v-row class="pa-0">
		<v-col cols="12" md="12" lg="12" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #22344f 0%, #1e2a3c 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500"> Mi Codigo de Referido: {{ $store.state.codreferido}}</v-list-item-title>
							
						</v-list-item-content>

						
					</v-list-item>

					
				</v-card>
			</v-col>
			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #23a450 0%, #50c771 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500">{{totalv}} UND</v-list-item-title>
							<v-list-item-subtitle>
								<small>Depositos - Balances</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d" size="15">UND</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					
				</v-card>
			</v-col>
			<v-col cols="12" md="3" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(140deg, #ffb900 0%, #ffde85 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500"> {{totalp}} UND</v-list-item-title>
							<v-list-item-subtitle>
								<small>Retiros + Bonos</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d" size="15">UND</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #307ee0, #01aeef)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500"> {{resta}} UND</v-list-item-title>
							<v-list-item-subtitle>
								<small>Diferencia</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d" size="15">UND</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					
				</v-card>
			</v-col>
			<v-col cols="12" md="6" lg="3" class style="padding-top:1px!important;">
				<v-card
					class="mx-auto"
					dark
					style="background-image: linear-gradient(-40deg, #23a450 0%, #50c771 100%)"
				>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="body-1" style="font-weight:500" v-if="resta>0"> {{((resta)*0.2).toFixed(3)}}  UND</v-list-item-title>
							<v-list-item-title class="body-1" style="font-weight:500" v-else> 0  UND</v-list-item-title>
							<v-list-item-subtitle>
								<small>Recompensa</small>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-avatar color="white">
							<v-icon color="#32325d" size="15">UND</v-icon>
						</v-list-item-avatar>
					</v-list-item>

					
				</v-card>
			</v-col>
	</v-row>
	<v-card :color="colors.sidebar">
		<v-card-text class="py-0 px-0">
			<v-card-title class="py-2 d-flex white--text">
				<b
					class="mt-1"
					style="font-weight:500;font-size:14px"
					v-if="$store.state.windowSize.x > 960"
				>MIS REFERIDOS</b>
				<filter-table v-model="search" :dateTo="date" :dateFrom="date1" @ok="updateTable"></filter-table>
			</v-card-title>
			<v-divider></v-divider>
			<v-data-table
				class="horse"
				:loading="loading"
				:height="$store.state.windowSize.x < 940?'calc(100vh - 296px)':'calc(100vh - 210px)'"
				
				:headers="headers"
				:items="rewards"
				show-expand
				:expanded.sync="expandedPre"
				:items-per-page="10"
				item-key="nombre"
				:search="search"
			>
				
			</v-data-table>
		</v-card-text>
	</v-card>
	</div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

import date from "../../date.js";
import statusColor from "../../statusColor.js";
import parseDate from "../../parseDate.js";
import filterTable from "../../components/filterTable.vue";

export default {
	components: {
		filterTable,
	},
	data() {
		return {
			loading: false,
			expandedPre: [],
			search: "",
			rewards: [],
			date: date.primerDia(),
			date1: date.today(),
			totalv:0,
			totalp:0,
			resta:0,
			headers: [
				{ text: "", value: "data-table-expand" },
				{ text: "USUARIO", value: "nombre" },
				{ text: "BALANCE", value: "balance" },
				{ text: "BONOS", value: "bonos" },
				{ text: "INGRESOS", value: "vendidos" },
				{ text: "RETIROS", value: "pagados" },
				{ text: "DIFERENCIA", value: "resta" },
				// { text: "CARRERA", value: "idcarrera", align: "center" },
				//{ text: "EXPIRA EL ", value: "idjugadas" },
				//{ text: "PAGO", value: "pago", align: "center" },
				//{ text: "", value: "actions", align: "center" },
			],
		};
	},
	watch: {
    
    idmoneda(e) {
      this.getRewards(e);
    },
  },
	created() {
		this.parseDate();
		this.date=date.primerDia();
	},
	mounted() {
		this.$store.state.codreferido = sessionStorage.getItem("codreferido");
		this.getRewards();
	},
	computed: {
		idmoneda() {
			return this.$store.state.idmoneda;
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		...mapActions(["getSaldo"]),
		parseDate,
		statusColor,
		updateTable(date, date1) {
			this.date = date;
			this.date1 = date1;
			this.getRewards();
		},
		getRewards() {
			this.loading = true;
			axios({
				method: "get",
				url: `reporte_referidos/${this.date}/${this.date1}/${this.$store.state.codreferido}`,
			}).then((response) => {
				this.rewards = response.data.premiados;
				this.totalv = response.data.totalv.toFixed(2);
				this.totalp = response.data.totalp.toFixed(2);
				this.resta = (this.totalv-this.totalp).toFixed(2);
				
				this.loading = false;
				
			});
		},
		pagarTicket(value) {
			this.loading = true;
			axios({
				method: "post",
				url: `new_pagarticket`,
				data: {
					idticket: value,
				},
			}).then((response) => {
				// console.log(response);
				this.getRewards();
				this.getPremios();
				this.$store.state.msg = response.data.mensaje;
				this.loading = false;
				this.$store.state.snack = true;
				this.getSaldo();
			});
		},
		getPremios() {
			axios({
				method: "get",
				url: `/new_premios`,
			}).then((response) => {
				this.$store.state.premios = response.data.premios[0];
			});
		},
	},
};
</script>

<style>
</style>