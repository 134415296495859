<template>
	<v-navigation-drawer :value="drawer" @input="update" absolute temporary>
		<!-- <v-btn
			:color="colors.perfil"
			dark
			rounded
			depressed
			class="px-2 justify-start"
			x-large
			block
			style="border-top-right-radius: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
		>
			<div class="d-flex align-center">
				<v-icon dark size="38">mdi-account-circle</v-icon>
				<div class="d-block ml-2">
					<span class="d-flex" style="line-height: 1.1;font-weight:500;">{{$store.state.nombre}}</span>
					<small
						class
						style=";font-weight:500;line-height: 0;"
					>{{$store.state.saldo.toLocaleString()}} UND</small>
				</div>
			</div>
		</v-btn>-->
		<v-list nav dense>
			<v-list-item-group>
				<v-list-item to="/profile">
					<v-list-item-title>Dashboard</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/rewards">
					<v-list-item-title>Ticket premiados</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/operations">
					<v-list-item-title>Operaciones</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/plays">
					<v-list-item-title>Jugadas</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/referidos">
					<v-list-item-title>Mis Referidos</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/Accounts">
					<v-list-item-title>Mis cuentas</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/Withdrawals">
					<v-list-item-title>Historial de retiros</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item to="/Recharges">
					<v-list-item-title>Historial de recargas</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item @click="$store.state.dialogChangePass= true;drawer=false">
					<v-list-item-title>Cambiar contraseña</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item
					dark
					:style="'background:'+colors.primary+';color:#fff'"
					@click="$store.state.dialogRe = true;drawer=false"
				>
					<v-list-item-title>RECARGAR SALDO</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>

				<v-list-item
					dark
					style="color:#fff;background-image: linear-gradient(60deg, #a50005 0%, #ff0008 100%);"
					@click="$router.push('/profile');dialogPay = true;drawer=false"
				>
					<v-list-item-title>SOLICITAR PAGO</v-list-item-title>
					<v-list-item-action>
						<v-icon class="ml-auto">mdi-menu-right</v-icon>
					</v-list-item-action>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	props: {
		drawer: Boolean,
	},
	computed: {
		marca() {
			return this.$store.state.marca;
		},
		dialogPay: {
			get() {
				return this.$store.state.dialogPay;
			},
			set(v) {
				this.$store.state.dialogPay = v;
			},
		},
		colors() {
			return this.$store.state.colors;
		},
	},
	methods: {
		update(e) {
			this.$emit("update", e);
		},
	},
};
</script>

<style>
</style>