<template>
	<div>
		<v-card :color="colors.sidebar">
			<v-card-text class="py-0 px-0">
				<v-card-title class="white--text py-2 d-flex align-center">
					<b class="mt-1" style="font-weight:500;font-size:14px" v-if="size > 960">MIS CARTERAS</b>
					<v-btn
						:color="colors.primary"
						dark
						class="ml-auto mb-2 mb-lg-0"
						:block="size<960"
						@click="dialogAccount = true;"
					>
						<v-icon>mdi-plus</v-icon>AÑADIR CARTERA
					</v-btn>
					<!-- <v-text-field
						:color="colors.perfil"
						class="field-date ml-2"
						:style="size > 960 ? 'margin:0px 0 0;padding:0; max-width:212px':''"
						background-color="#f5f5f5"
						single-line
						dense
						solo
						flat
						hide-details
						v-model="search"
						append-icon="mdi-magnify"
						label="Buscar"
					></v-text-field>-->
				</v-card-title>
				<v-divider></v-divider>
				<div
					class="pa-3 white"
					style="border-bottom-left-radius:4px;border-bottom-right-radius:4px;height:calc(100vh - 152px);overflow:auto"
				>
					<v-row>
						<v-col cols="12" md="4" v-for="(saldo,idx) in saldos" :key="idx">
							<v-card class="mx-auto" outlined @click="$router.push(`/accounts/${idx}/${saldo.idmoneda}`)">
								<!-- <v-progress-circular
									class="d-flex mx-auto mt-11"
							
									indeterminate
									color="black"
									:width="3"
								></v-progress-circular>-->
								<div class="d-flex">
									<v-img
										contain
										:aspect-ratio="3"
										src="https://kit8.net/images/detailed/4/Wads_of_money.png"
									></v-img>
									<div class="text-center">
										<v-card-title class="mx-auto">
											<b>{{saldo.simbolo}}({{saldo.moneda}}) {{saldo.pais =='EEUU'?'- EEUU':saldo.pais =='ECUADOR'?'- ECUADOR':''}}</b>
										</v-card-title>
										<v-card-subtitle class="mx-auto" style="line-height: 1;">
											<b>{{saldo.balance}} UND</b>
											<b class="d-block">
												<small>Bono: {{saldo.bono}} UND</small>
											</b>
										</v-card-subtitle>
									</div>
								</div>
								<div class="d-flex">
									<b
										class="ml-3"
									>SALDO: {{saldo.simbolo}} {{parseFloat(saldo.cambio)*parseFloat(saldo.balance)}}</b>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
		</v-card>
		<v-dialog v-model="dialogAccount" max-width="350" scrollable>
			<v-card flat>
				<v-card-text>
					<v-card-title class="px-0 body-1">
						<b style="font-weight:500" class="color">Nueva cartera</b>
					</v-card-title>
					<v-select
						:color="colors.perfil"
						class="mb-3 register"
						label="Moneda"
						single-line
						dense
						solo
						flat
						background-color="blue-grey lighten-5"
						hide-details
						v-model="moneda"
						required
						item-text="moneda"
						item-value="idmoneda"
						type="currency"
						:items="contryCurrency"
					>
						<template slot="item" slot-scope="data">
							<!-- HTML that describe how select should render items when the select is open -->
							{{ data.item.simbolo }} ({{ data.item.moneda }}) {{ data.item.paisnombre == 'ECUADOR'? '- ECUADOR': data.item.paisnombre == 'EEUU'? '- EEUU':''}}
						</template>
					</v-select>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn text :loading="loading" color="#eb262c" @click="addCartera()">Aceptar</v-btn>
					<v-btn text color="#eb262c" @click="dialogAccount = false">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			loading: false,
			dialogAccount: false,
			moneda: "",
			saldos: [],
		};
	},
	created() {
		this.getSaldo();
	},
	methods: {
		getSaldo() {
			axios({
				method: "get",
				url: `/new_saldo`,
			}).then((response) => {
				this.saldos = response.data.saldo;
			});
		},
		addCartera() {
			this.loading = true;
			axios({
				method: "post",
				url: `/nueva_cartera`,
				data: {
					idmoneda: this.moneda,
				},
			}).then((response) => {
				if (response.data.mensaje == "Cartera Creada con exito") {
					this.getSaldo();
				}
				this.$store.state.msg = response.data.mensaje;
				this.$store.state.snack = true;
				this.dialogAccount = false;
				this.loading = false;
			});
		},
	},
	computed: {
		contryCurrency() {
			console.log(this.$store.state.contryCurrency);
			return this.$store.state.contryCurrency;
		},

		accTypeId() {
			return this.accountsTypes.filter((item) => {
				return item.tipo == this.selectedAccountType.tipo ? item.id : "";
			});
		},
		size() {
			return this.$store.state.windowSize.x;
		},
		colors() {
			return this.$store.state.colors;
		},
		nombre() {
			return this.$store.state.nombre;
		},
	},
};
</script>

<style>
</style>