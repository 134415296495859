<template>
	<div class="back">
		<v-app id="inspire" style="background:transparent;">
			<v-app-bar app style="background:#fffffff0">
				<v-toolbar-title>
					<v-img src="../assets/img/logo.svg" max-width="80" contain></v-img>
				</v-toolbar-title>
				<span class="t-title ml-2">TRACK & RACES</span>

				<v-spacer></v-spacer>

				<v-btn text color="#00afef">Apuesta</v-btn>
				<v-btn text color="#2463b6">Resultados</v-btn>
				<v-btn text color="#00afef">Programas</v-btn>
				<v-btn text color="#2463b6" to="/profile">Perfil</v-btn>
				<v-btn text color="red">Salir</v-btn>
			</v-app-bar>

			<v-content class="pt-12">
				<v-container fluid style="background:#0000004d; height:100%;">
					<v-row class="mt-1">
						<v-col cols="3">
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<v-btn color="primary" block class="text-end select-event px-4" x-large dark v-on="on">
										<div class="d-flex align-center mr-auto">
											<v-img src="../assets/img/hipo.svg" aspect-ratio="1" width="32px"></v-img>
											<span class="ml-3">{{selectedEventType}}</span>
										</div>
										<v-icon class>mdi-menu-down</v-icon>
									</v-btn>
								</template>
								<v-list class="mt-2">
									<v-list-item
										v-for="(item, index) in eventType"
										:key="index"
										@click="selectedEventType=item"
									>
										<v-img v-if="index==0" src="../assets/img/hipo.svg" aspect-ratio="1" width="32px"></v-img>
										<v-img v-if="index==1" src="../assets/img/galgos.svg" aspect-ratio="1" width="32px"></v-img>
										<v-img v-if="index==2" src="../assets/img/carretas.svg" aspect-ratio="1" width="32px"></v-img>
										<v-list-item-title style="font-weight:600" class="ml-2">{{ item }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
							<v-list two-line flat color="transparent" v-if="selectedEventType == 'HIPODROMOS'">
								<v-list-item
									v-for="item in hipodromos"
									:key="item.nombre"
									class="event-item mb-1 py-0"
									@click="changeEvent(item)"
								>
									<v-list-item-content class="py-0">
										<v-list-item-title>
											<b style="font-size:15px">{{item.nombre}}</b>
										</v-list-item-title>
										<v-list-item-subtitle>
											<small>
												<b>EN CURSO - CARRERA {{item.idcarrera}}</b>
											</small>
										</v-list-item-subtitle>
									</v-list-item-content>

									<v-list-item-action>
										<v-chip class small dark label color="#00afef" v-if="item.estatus == 'ABIERTA'">
											<small>
												<b>{{item.estatus}}</b>
											</small>
										</v-chip>
										<v-chip class small label color="#ffc107" v-if="item.estatus == 'CORRIENDO'">
											<small>
												<b>{{item.estatus}}</b>
											</small>
										</v-chip>
										<v-chip class small dark label color="#eb262c" v-if="item.estatus == 'CERRADA'">
											<small>
												<b>{{item.estatus}}</b>
											</small>
										</v-chip>
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<v-list two-line flat color="transparent" v-if="selectedEventType == 'GALGOS'">
								<v-list-item v-for="item in galgos" :key="item.title" class="event-item mb-1 py-0">
									<v-list-item-content class="py-0">
										<v-list-item-title v-text="item.title"></v-list-item-title>
										<v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
									</v-list-item-content>

									<v-list-item-action>
										<v-chip class small dark label color="#00afef">Abierta</v-chip>
									</v-list-item-action>
								</v-list-item>
							</v-list>

							<v-list two-line flat color="transparent" v-if="selectedEventType == 'CARRETAS'">
								<v-list-item v-for="item in carretas" :key="item.title" class="event-item mb-1 py-0">
									<v-list-item-content class="py-0">
										<v-list-item-title v-text="item.nombre"></v-list-item-title>
										<v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
									</v-list-item-content>

									<v-list-item-action>
										<v-chip class small dark label color="#00afef">Abierta</v-chip>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</v-col>
						<v-col cols="6">
							<div class="event-details d-flex align-center px-4 mb-2">
								<b class="event-title">{{selectedEvent.nombre}}</b>
								<v-divider
									vertical
									class="my-auto ml-2"
									style="border-width: 0 thin 0 1px;min-height:24px;height:10px"
									dark
								></v-divider>
								<v-menu offset-y>
									<template v-slot:activator="{ on }">
										<v-btn text class="px-2" dark v-on="on">
											Carrera {{selectedEvent.idcarrera}}
											<v-icon class>mdi-menu-down</v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item v-for="(item, index) in races" :key="index" @click="changeRace(item)">
											<v-list-item-title class="d-flex">
												<small class="mr-2">
													<b>CARRERA {{ item.idcarrera }}</b>
												</small>
												<v-chip
													class="ml-auto"
													x-small
													dark
													label
													color="#00afef"
													v-if="item.estatus == 'ABIERTA'"
												>
													<b>{{item.estatus}}</b>
												</v-chip>
												<v-chip
													class="ml-auto"
													x-small
													label
													color="#ffc107"
													v-if="item.estatus == 'CORRIENDO'"
												>
													<b>{{item.estatus}}</b>
												</v-chip>
												<v-chip
													class="ml-auto"
													x-small
													dark
													label
													color="#eb262c"
													v-if="item.estatus == 'CERRADA'"
												>
													<b>{{item.estatus}}</b>
												</v-chip>
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
								<v-divider
									vertical
									class="my-auto mr-2"
									style="border-width: 0 thin 0 1px;min-height:24px;height:10px"
									dark
								></v-divider>
								<v-chip small dark label color="#00afef" v-if="selectedEvent.estatus == 'ABIERTA'">
									<b>{{selectedEvent.estatus}}</b>
								</v-chip>
								<v-chip small label color="#ffc107" v-if="selectedEvent.estatus == 'CORRIENDO'">
									<b>{{selectedEvent.estatus}}</b>
								</v-chip>
								<v-chip small dark label color="#eb262c" v-if="selectedEvent.estatus == 'CERRADA'">
									<b>{{selectedEvent.estatus}}</b>
								</v-chip>
							</div>
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="pt-1 text-center">Gate</th>
											<th class="pt-1 text-left">Horse</th>
											<th class="pt-1 text-left">Jockey</th>
											<th class="pt-1 text-left">ML</th>
											<th class="pt-1 text-center">
												<b
													class="pt-1"
													v-if="selectedGameType == 'GANADOR' || selectedGameType == 'PLACE' || selectedGameType == 'SHOW' || selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>1.º</b>
												<b
													class="pt-1 ml-5"
													v-if="selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>2.º</b>
												<b
													class="pt-1 ml-5"
													v-if="selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
												>3.º</b>
												<b class="pt-1 ml-5" v-if="selectedGameType == 'SUPERFECTA'">4.º</b>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="horse in horses" :key="horse.idejemplares">
											<td style="width: 1px;">
												<span :class="'gate gate-'+horse.idejemplares">{{ horse.idejemplares}}</span>
											</td>
											<td style="font-weight:500">{{ horse.nombre_ejemplar}}</td>
											<td>{{ horse.jinete}}</td>
											<td>
												<b>{{ horse.ml}}</b>
											</td>
											<td>
												<div class="d-flex">
													<div
														class="t-container"
														v-if="selectedGameType == 'GANADOR' || selectedGameType == 'PLACE' || selectedGameType == 'SHOW' || selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
													>
														<div class="round">
															<input
																v-model="jugada"
																type="checkbox"
																:value="horse.idejemplares"
																:id="'checkbox'+horse.idejemplares"
															/>
															<label :for="'checkbox'+horse.idejemplares"></label>
														</div>
													</div>

													<div
														class="t-container"
														v-if="selectedGameType == 'EXACTA' || selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
													>
														<div class="round">
															<input
																v-model="jugada2"
																type="checkbox"
																:value="horse.idejemplares"
																:id="'checkbox'+horse.idejemplares+'1'"
															/>
															<label :for="'checkbox'+horse.idejemplares+'1'"></label>
														</div>
													</div>

													<div
														class="t-container"
														v-if="selectedGameType == 'TRIFECTA' || selectedGameType == 'SUPERFECTA'"
													>
														<div class="round">
															<input
																v-model="jugada3"
																type="checkbox"
																:value="horse.idejemplares"
																:id="'checkbox'+horse.idejemplares+'2'"
															/>
															<label :for="'checkbox'+horse.idejemplares+'2'"></label>
														</div>
													</div>

													<div class="t-container" v-if="selectedGameType == 'SUPERFECTA'">
														<div class="round">
															<input
																v-model="jugada4"
																type="checkbox"
																:value="horse.idejemplares"
																:id="'checkbox'+horse.idejemplares+'3'"
															/>
															<label :for="'checkbox'+horse.idejemplares+'3'"></label>
														</div>
													</div>
												</div>
												<!-- <v-checkbox class="check" v-model="jugada" :value="horse.idejemplares"></v-checkbox> -->
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-container>
			</v-content>
			<v-footer dark app>
				<span class="white--text">&copy; 2020 Tack & Races</span>
			</v-footer>
		</v-app>
	</div>
</template>

<script>
export default {
  
};
</script>

<style>
</style>