var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":_vm.colors.sidebar}},[_c('v-card-text',{staticClass:"py-0 px-0"},[_c('v-card-title',{staticClass:"py-2 d-flex align-center"},[(_vm.$store.state.windowSize.x > 960)?_c('b',{staticClass:"mt-1 white--text",staticStyle:{"font-weight":"500","font-size":"14px"}},[_vm._v("HISTORIAL DE RECARGAS")]):_vm._e()]),_c('v-divider'),_c('v-data-table',{staticClass:"horse",attrs:{"loading":_vm.loading,"height":_vm.$store.state.windowSize.x < 940?'calc(100vh - 254px)':'calc(100vh - 210px)',"fixed-header":"","headers":_vm.headers,"items":_vm.recharges,"expanded":_vm.expanded,"items-per-page":10,"show-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"normal",staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"left","background":"transparent !important","box-shadow":"none"}},[_vm._v("Banco")]),_c('th',{staticStyle:{"text-align":"left","background":"transparent !important","box-shadow":"none"}},[_vm._v("Observaciones")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.banco))]),_c('td',[_vm._v(_vm._s(item.observaciones))])])])])])]}},{key:"item.data-table-expand",fn:function(ref){
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!isExpanded)?_c('v-icon',{attrs:{"size":"28","color":"green"},on:{"click":expand}},[_vm._v("mdi-plus-circle")]):_c('v-icon',{attrs:{"size":"28","color":"#eb262c"},on:{"click":function($event){return expand(false)}}},[_vm._v("mdi-minus-circle")])]}},{key:"item.ref_deposito",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v("#"+_vm._s(item.ref_deposito))])]}},{key:"item.banco",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.banco == null? item.n_cuenta:item.banco))])]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.statusColor(item.tipo),"dark":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.tipo == 'apuesta'),expression:"item.tipo == 'apuesta'"}]},[_vm._v("-")]),_vm._v(" "+_vm._s(item.monto)+" UND ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }