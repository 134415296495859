<template>
	<v-dialog v-model="open" persistent max-width="290">
		<v-card>
			<v-card-title class="body-1 color">{{title}}</v-card-title>
			<v-card-text>{{text}}</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn
					color="#eb262c"
					class="px-5 mr-2"
					outlined
					depressed
					small
					rounded
					:dark="!loading"
					:disabled="loading"
					@click="close()"
				>NO</v-btn>
				<v-btn
					color="#00afef"
					class="px-6"
					:dark="!loading"
					depressed
					rounded
					small
					:disabled="loading"
					:loading="loading"
					@click="ok()"
				>SI</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		open: Boolean,
		title: String,
		text: String,
		loading: Boolean
	},
	methods: {
		ok() {
			this.$emit("ok");
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style>
</style>