<template>
  <div align = "center">
    <v-overlay :value="overlay" style="height: 100vh">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <iframe
      :src="gameUrl"
      :style="
        size < 960
          ? 'width: 100%; height: calc(100vh - 16px)'
          : 'width: 100%; height: calc(100vh - 95px)'
      "
      frameborder="0"
    ></iframe>
    
  </div>
</template>

<script>
import axios from "axios";

import { mapActions } from "vuex";
export default {
  data() {
    return {
      gameUrl: null,
      overlay: true,
      isMobile: 0,
      mon:null
    };
  },
  watch: {
    idmoneda() {
      this.overlay = true;
      this.getGame();
    },
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
 async beforeCreated(){
    await axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.nombre = response.data.success.name;
    });
  },
  mounted() {
    this.isMobile = window.innerWidth < 600 ? 1 : 0;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    axios({
      method: "post",
      url: `details`,
    }).then((response) => {
      this.$store.state.nombre = response.data.success.name;
    });
    this.getSaldo();

    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
    this.idpos = sessionStorage.getItem("idpos");
    this.getGame();
  },
  updated() {
    if (document.getElementById("fc_frame")) {
      document.getElementById("fc_frame").style.display = "none";
    }
  },
  methods: {
    ...mapActions(["getSaldo"]),
    onResize() {
      this.isMobile = window.innerWidth < 600 ? 1 : 0;
    },
    getGame() {

      this.mon='';
      if(this.$store.state.idmoneda==1)
      this.mon='VES'
      else if(this.$store.state.idmoneda==2)
      this.mon='USD'
      else if(this.$store.state.idmoneda==3)
      this.mon='PEN'
      else if(this.$store.state.idmoneda==7)
      this.mon='COP'
      else if(this.$store.state.idmoneda==8)
      this.mon='CLP'
      

      axios({
				method: "post",
				url: `https://apicasiersweb.elinmejorable.bet/trackandraces/login`,
				data: {
					email: this.$store.state.idpos+"@tr.com",
					cedula: this.$store.state.cedula,
					usuario: this.$store.state.idpos,
					empresa: "Trackandraces",
					SubAgenteNombre: this.$store.state.email,
          alias: this.$store.state.nombre+"-"+this.$store.state.idpos,
					moneda: this.mon,
          type: "marcas",
					api_key: "90ff35de-0ff9-11ec-82a8-0242ac130003"
				},
			}).then((response) => {
                
                console.log(response);
                
                  const url = response.data.data.url;
                  this.gameUrl = url.replace("horse","marcas")
                  console.log(this.gameUrl);
                  //alert("Las unidades de su Cartera seran convertidas en Bolivares para realizar las Jugadas");
                  
                this.overlay = false;
			});
    },
  },
  computed: {
    size() {
      return this.$store.state.windowSize.x;
    },
    moneda() {
      return this.$store.state.moneda;
    },
    idmoneda() {
      return this.$store.state.idmoneda;
    },
    idsb() {
      return this.$store.state.idsb;
    },
    currentBalance() {
      return this.$store.state.currentBalance;
    },
    idpos: {
      get() {
        return this.$store.state.idpos;
      },
      set(e) {
        this.$store.state.idpos = e;
      },
    },
  },
};
</script>

<style></style>
