import date from "./date.js";

export default function () {
  if (date.today().split("-")[2] <= 7) {
    this.date =
      date.today().split("-")[0] +
      "-" +
      date.today().split("-")[1] +
      "-" +
      date.today().split("-")[2];
  } else {
    this.date =
      date.today().split("-")[0] +
      "-" +
      date.today().split("-")[1] +
      "-" +
      (parseInt(date.today().split("-")[2]) - 8);
  }
}